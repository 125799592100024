import React, { HTMLAttributes } from "react"

interface SVGComponent extends HTMLAttributes<HTMLOrSVGElement> {}

export const BackgroundImage = ({ ...rest }: SVGComponent) => {
  return (
    <>
       <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1362.966 674.211"
          { ...rest }
        >
          <path
            id="login-hero-bg"
            d="M550.488,228.944c-68.8,4.008-198.312-171.361-318.174,78.037C174.54,467.046,252.35,556.359,257.881,786.154c-6.035,236.772-77.2,377.43-38.879,544.535s121.117,208.671,294.781,204.8S843.615,1399.322,792.107,1247.5C632.35,895.781,948.082,553.8,868.992,324.81S619.285,224.935,550.488,228.944Z"
            transform="translate(1535.729 -207.726) rotate(90)"
          />
        </svg>
    </>
  )
}
