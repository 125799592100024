import React, { ChangeEvent, HTMLInputTypeAttribute } from "react"

interface FormFieldProps {
  errorMsg: string
  fieldName: string
  label: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  prefix?: string
  required?: boolean
  success?: boolean
  type?: HTMLInputTypeAttribute
  value: string
}

export const FormField = ({
  errorMsg,
  fieldName,
  label,
  onChange,
  prefix = "fr-login-form",
  required,
  success,
  type = "text",
  value,
}: FormFieldProps) => {
  const errorMessageClass = errorMsg !== "" ? "-error--active" : "-error",
    errorFieldClass = errorMsg !== "" ? "--validation-error" : "",
    successFieldClass = success ? "-success--active" : "-success"

  return (
    <div className={`${prefix}__field-wrapper`}>
      <div className={`${prefix}__label-wrapper`}>
        <label className={`${prefix}__label`} htmlFor={fieldName}>
          {label}
        </label>
        <input
          className={`${prefix}__field${errorFieldClass}`}
          id={fieldName}
          name={fieldName}
          onChange={onChange}
          required={required}
          type={type}
          value={value}
        />
      </div>
      {success && <div className={`${prefix}__field${successFieldClass}`} />}
      <span className={`${prefix}__field${errorMessageClass}`}>
        {errorMsg !== "" && errorMsg}
      </span>
    </div>
  )
}
