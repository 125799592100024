import React from "react"
import { graphql } from "gatsby"
import "components/hero/hero-common.module.scss"
import "./login-hero.module.scss"

import { BackgroundImage } from './assets/BackgroundImage';
import { Banner } from "../HeroBanner-types"
import { HeroContent } from "components/hero/HeroContent"
import { LoginForm } from "./LoginForm"
import { useWindowDimensions } from "hooks/useWindowDimensions"

interface LoginBannerProps {
  banner: Banner
}

export const LoginHero = ({ banner }: LoginBannerProps) => {
  const { heading, sub_heading } = banner
  const { size } = useWindowDimensions()
  const prefix: string = 'fr-login-hero'

  const wrapperClasses = size > 550
    ? `${prefix}__wrapper fr-container`
    : `${prefix}__wrapper`

  return (
    <section className={ prefix }>
      <div className={ wrapperClasses }>
        <div className={ `${prefix}__banner-content` }>
          <HeroContent
            baseClassName="fr-login-hero__banner-content"
            heading={ heading?.value }
            subHeading={ sub_heading }
          />

          <LoginForm />

          { size > 1024 && <BackgroundImage className={ `${prefix}__background-image` } /> }
        </div>
        { size <= 1024 && <BackgroundImage className={ `${prefix}__background-image` } /> }
      </div>
    </section>
  )
}

export const query = graphql`
  fragment LoginBannerFragment on kontent_item_hero_banner {
    id
    elements {
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`
